import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductModal from "./ProductModal";
import LivePreview from "./LivePreview";
import { SliderPicker } from "react-color";
import {
  fontFamilies,
  fontSizes,
  INITIAL_Font_Size_name,
  INITIAL_nameFontSizeMobile,
  INITIAL_priceFontSizeMobile,
  INITIAL_TITLE_COLOR,
  INITIAL_TITLE_FONT_FAMILY,
  INITIAL_titleFontSizeMobile,
  sizes,
} from "../constant";
import { Button, Form } from "react-bootstrap";
import QRCode from "react-qr-code";

const BannerForm = ({ onFormSubmit }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [background, setBackground] = useState(null);
  const [title, setTitle] = useState("");
  const [discription, setDiscription] = useState("");
  const [qrText, setQrText] = useState("");
  const [generatedQR, setGeneratedQR] = useState("");
  const [footer, setFooter] = useState("");
  const [product, setProduct] = useState("");
  const [size, setSize] = useState("");
  const [titleColor, setTitleColor] = useState(INITIAL_TITLE_COLOR);
  const [nameColor, setNameColor] = useState(INITIAL_TITLE_COLOR);
  const [priceColor, setPriceColor] = useState(INITIAL_TITLE_COLOR);
  const [productDetails, setProductDetails] = useState([]);
  const [showFooterInput, setShowFooterInput] = useState(false);
  const [showTC, setShowTC] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Define state for font sizes and font families
  const [titleFontSize, setTitleFontSize] = useState("");
  const [titleFontSizeMobile, setTitleFontSizeMobile] = useState(
    INITIAL_titleFontSizeMobile
  );
  const [nameFontSizeMobile, setNameFontSizeMobile] = useState(
    INITIAL_nameFontSizeMobile
  );
  const [priceFontSizeMobile, setPriceFontSizeMobile] = useState(
    INITIAL_priceFontSizeMobile
  );

  const [titleFontFamily, setTitleFontFamily] = useState(
    INITIAL_TITLE_FONT_FAMILY
  );
  const [nameFontSize, setNameFontSize] = useState(INITIAL_Font_Size_name);
  const [priceFontSize, setPriceFontSize] = useState("");
  const [price, setPrice] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [customPrice, setCustomPrice] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [isEditRegular, setIsEditRegular] = useState(false);
  /**
   * Function to resize an image file and add optional footer text and T&C text.
   * The function reads the image, resizes it to fit within specified dimensions
   * while maintaining the aspect ratio, and optionally adds footer text and
   * T&C text before returning the resized image file.
   *
   * @param {File} file - The image file to be resized.
   * @returns {Promise<File>} - A promise that resolves with the resized image file.
   *
   * The resizing process includes:
   * - Reading the image file using FileReader.
   * - Creating an Image object and setting its source to the file data.
   * - Calculating the target width and height based on the selected size
   *   and aspect ratio.
   * - Drawing the resized image onto a canvas.
   *
   * Optional features:
   * - Adding footer text: The text is wrapped and centered at the bottom of
   *   the image if the `footer` variable is provided.
   * - Adding "T&C" text: If the `showTC` variable is true, the text "T&C" is
   *   added in the bottom-left corner of the image.
   *
   * Finally, the canvas content is converted to a Blob and wrapped in a File object,
   * which is then returned by resolving the promise.
   */

  const handleImageResize = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const selectedSize = sizes.find((s) => s.name === size);
          const canvas = document.createElement("canvas");
          const aspectRatio =
            selectedSize.dimensions.width / selectedSize.dimensions.height;
          let targetWidth, targetHeight;

          if (img.width / img.height > aspectRatio) {
            targetHeight = selectedSize.dimensions.height;
            targetWidth = targetHeight * (img.width / img.height);
          } else {
            targetWidth = selectedSize.dimensions.width;
            targetHeight = targetWidth * (img.height / img.width);
          }

          canvas.width = selectedSize.dimensions.width;
          canvas.height = selectedSize.dimensions.height;
          const ctx = canvas.getContext("2d");

          const xOffset = (selectedSize.dimensions.width - targetWidth) / 2;
          const yOffset = (selectedSize.dimensions.height - targetHeight) / 2;

          ctx.drawImage(img, xOffset, yOffset, targetWidth, targetHeight);

          // Function to wrap text
          const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
            const words = text.split(" ");
            let line = "";
            for (let n = 0; n < words.length; n++) {
              const testLine = line + words[n] + " ";
              const metrics = ctx.measureText(testLine);
              const testWidth = metrics.width;
              if (testWidth > maxWidth && n > 0) {
                ctx.fillText(line, x, y);
                line = words[n] + " ";
                y += lineHeight;
              } else {
                line = testLine;
              }
            }
            ctx.fillText(line, x, y);
          };

          // Add footer text
          if (footer) {
            ctx.font = `28px ${titleFontFamily}`;
            ctx.fillStyle = titleColor;
            ctx.textAlign = "center";
            const maxWidth = canvas.width - 20; // Set max width for text
            const lineHeight = 30;
            const startY = canvas.height - 40;
            wrapText(
              ctx,
              footer,
              canvas.width / 2,
              startY,
              maxWidth,
              lineHeight
            );
          }

          // Add T&C text if checkbox is checked
          if (showTC) {
            ctx.font = `35px ${titleFontFamily}`;
            ctx.fillStyle = titleColor;
            ctx.textAlign = "left";
            ctx.fillText("T&C", 10, canvas.height - 10); // Position the T&C text
          }

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(resizedFile);
          }, file.type);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!background) {
      toast.error("Background image is required.");
      return;
    }

    if (!title.trim()) {
      toast.error("Title is required.");
      return;
    }

    if (!product.trim()) {
      toast.error("Product name is required.");
      return;
    }

    if (!size) {
      toast.error("Size selection is required.");
      return;
    }

    let resizedImage = null;
    if (background) {
      resizedImage = await handleImageResize(background);
    }

    const selectedProduct = productDetails.find(
      (p) => p.name?.toLowerCase() === product.toLowerCase()
    );
    if (!selectedProduct) {
      toast.error("Invalid product selection.");
      return;
    }

    onFormSubmit({
      background: resizedImage,
      title,
      product: selectedProduct,
      size,
      titleColor,
      nameColor,
      priceColor,
      titleFontSize,
      titleFontFamily,
      nameFontSize,
      priceFontSize,
      customPrice: customPrice,
    });
  };

  const randomBorderRadius = Math.floor(Math.random() * 15) + "px";
  /**
   * Function to handle the selection of a product from a list.
   * When a product is selected, this function updates the state with
   * the selected product's name and details, and then closes the modal.
   *
   * @param {Object} selectedProduct - The product object that has been selected.
   *
   * The function performs the following steps:
   * - Updates the `product` state with the name of the selected product.
   * - Updates the `productDetails` state with an array containing the selected product.
   * - Closes the modal by setting the `isModalOpen` state to false.
   */

  const handleSelectProduct = (selectedProduct) => {
    setProduct(selectedProduct.name);
    setProductDetails([selectedProduct]); // Set the selected product details
    setIsModalOpen(false); // Close the modal
  };

  useEffect(() => {
    if (!isEditing) {
      setCustomPrice(price);
    }
  }, [isEditing, price]);

  const handlePriceChange = (e) => {
    setCustomPrice(e.target.value);
  };

  const handleBlur = () => {
    if (!isNaN(customPrice)) {
      setPrice(parseFloat(customPrice));
    }
    setIsEditing(false);
  };

  const handleGenerateQR = () => {
    setGeneratedQR(qrText);
  };
  const handleRegularPriceChange = (e) => {
    setRegularPrice(e.target.value); // Correctly update the price
  };

  const handleRegularBlur = () => {
    setIsEditRegular(false);
  };

  const [useUploadedBackground, setUseUploadedBackground] = useState(true);

  const handleCheckboxChange = () => {
    setUseUploadedBackground(!useUploadedBackground);
  };

  return (
    <>
      <div className="banner-generator-main">
        <div className="form-pnl">
          <div className="scrollable-form-container">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Upload Background Image:</Form.Label>

                <Form.Control
                  className="reg-btn-notification big w-100 br-rad colorBorder "
                  type="file"
                  accept="image/*"
                  onChange={(e) => setBackground(e.target.files[0])}
                />
                <Form.Check
        type="checkbox"
        label="Use uploaded background image"
        checked={useUploadedBackground}
        onChange={handleCheckboxChange}
        className="mt-3"
      />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select Font Family:</Form.Label>
                <Form.Select
                  required
                  className="form-control"
                  aria-label="Default select example"
                  value={titleFontFamily}
                  onChange={(e) => setTitleFontFamily(e.target.value)}
                >
                  {fontFamilies?.map((family) => (
                    <option key={family} value={family}>
                      {family}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Enter Title:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select Title Font Size:</Form.Label>

                <Form.Select
                  required
                  className="form-control"
                  aria-label="Default select example"
                  value={titleFontSize}
                  onChange={(e) => setTitleFontSize(e.target.value)}
                >
                  {fontSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select Title Color:</Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="color"
                  value={titleColor}
                  onChange={(e) => setTitleColor(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Product</Form.Label>
                <Button
                  className="reg-btn mb-3 big block button-border"
                  onClick={() => setIsModalOpen(true)}
                >
                  Select Product
                </Button>

                <div className="mt-2">
                  {product && (
                    <Form.Control
                      type="text"
                      value={product}
                      readOnly
                      onClick={() => setIsModalOpen(true)}
                    />
                  )}
                </div>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Select Product Name Font Size:</Form.Label>
                <Form.Select
                  required
                  className="form-control"
                  aria-label="Default select example"
                  value={nameFontSize}
                  onChange={(e) => setNameFontSize(e.target.value)}
                >
                  {fontSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select Product Name Color:</Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="color"
                  value={nameColor}
                  onChange={(e) => setNameColor(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Select Price Font Size:</Form.Label>
                <Form.Select
                  required
                  className="form-control"
                  aria-label="Default select example"
                  value={priceFontSize}
                  onChange={(e) => setPriceFontSize(e.target.value)}
                >
                  {fontSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Select Price Color:</Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="color"
                  value={priceColor}
                  onChange={(e) => setPriceColor(e.target.value)}
                />
              </Form.Group>
              <div>
                {productDetails.length > 0 &&
                  productDetails.find(
                    (p) => p.name.toLowerCase() === product.toLowerCase()
                  ) && (
                    <>
                      {(() => {
                        const selectedProduct = productDetails.find(
                          (p) => p.name.toLowerCase() === product.toLowerCase()
                        );
                        const adjustedPrice = selectedProduct.price * 0.01; // Calculate the adjusted price

                        return (
                          <>
                            <div>
                              <Form.Label>Custom Price</Form.Label>
                              {isEditing ? (
                                <Form.Control
                                  type="number"
                                  value={customPrice}
                                  onChange={handlePriceChange}
                                  onBlur={handleBlur}
                                  className="mb-3"
                                  // style={{
                                  //   color: priceColor,
                                  //   fontFamily: titleFontFamily,
                                  // }}
                                  autoFocus
                                />
                              ) : (
                                <Form.Control
                                  value={` $${
                                    customPrice
                                      ? customPrice
                                      : adjustedPrice.toFixed(2)
                                  }`}
                                  onClick={() => setIsEditing(true)}
                                />
                              )}
                            </div>
                          </>
                        );
                      })()}
                    </>
                  )}
              </div>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select Title Mobile NFT font size:</Form.Label>

                <Form.Select
                  required
                  className="form-control"
                  aria-label="Default select example"
                  value={titleFontSizeMobile}
                  onChange={(e) => setTitleFontSizeMobile(e.target.value)}
                >
                  {fontSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select Name Mobile NFT font size:</Form.Label>

                <Form.Select
                  required
                  className="form-control"
                  aria-label="Default select example"
                  value={nameFontSizeMobile}
                  onChange={(e) => setNameFontSizeMobile(e.target.value)}
                >
                  {fontSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select Price Mobile NFT font size:</Form.Label>

                <Form.Select
                  required
                  className="form-control"
                  aria-label="Default select example"
                  value={priceFontSizeMobile}
                  onChange={(e) => setPriceFontSizeMobile(e.target.value)}
                >
                  {fontSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>QR Generator:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter QR Text"
                    value={qrText}
                    onChange={(e) => setQrText(e.target.value)}
                  />
                  <Button
                    className="reg-btn mb-3 big block button-border"
                    onClick={handleGenerateQR}
                  >
                    Generate QR
                  </Button>
                </Form.Group>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>Add Discription:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Title"
                  value={discription}
                  onChange={(e) => setDiscription(e.target.value)}
                />
              </Form.Group>
              <div>
                <Form.Label>Regular Price</Form.Label>
                {isEditRegular ? (
                  <Form.Control
                    type="number"
                    value={regularPrice}
                    onChange={handleRegularPriceChange}
                    onBlur={handleRegularBlur}
                    className="mb-3"
                    autoFocus
                  />
                ) : (
                  <Form.Control
                    value={regularPrice ? `$${regularPrice}` : ""}
                    onClick={() => setIsEditRegular(true)}
                    placeholder="Enter Regular Price"
                  />
                )}
              </div>

              <Form.Group className="mb-3">
                {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check // prettier-ignore
                      type={type}
                      id={`default-${type}`}
                      label={`Show Footer Input`}
                      checked={showFooterInput}
                      onChange={(e) => setShowFooterInput(e.target.checked)}
                    />
                    {showFooterInput && (
                      <div className="footer-container">
                        <Form.Label>Enter Footer:</Form.Label>
                        <Form.Control
                          type="text"
                          value={footer}
                          onChange={(e) => setFooter(e.target.value)}
                        />
                      </div>
                    )}

                    <Form.Check
                      type={type}
                      label={`T & C`}
                      id={`disabled-default-${type}`}
                      checked={showTC}
                      onChange={(e) => setShowTC(e.target.checked)}
                    />
                  </div>
                ))}
              </Form.Group>
              <ToastContainer />
            </Form>
          </div>
        </div>
        <div className="banner-pnl">
          <LivePreview
            background={background}
            title={title}
            product={product}
            size={size}
            useUploadedBackground={useUploadedBackground}
            footer={footer}
            generatedQR={generatedQR}
            titleColor={titleColor}
            nameColor={nameColor}
            priceColor={priceColor}
            qrText={qrText}
            regularPrice={regularPrice}
            discription={discription}
            nameFontSizeMobile={nameFontSizeMobile}
            titleFontSize={titleFontSize}
            titleFontSizeMobile={titleFontSizeMobile}
            priceFontSizeMobile={priceFontSizeMobile}
            titleFontFamily={titleFontFamily}
            nameFontSize={nameFontSize}
            priceFontSize={priceFontSize}
            showTC={showTC}
            customPrice={customPrice}
            productDetails={productDetails}
          />
        </div>
      </div>

      <ProductModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onSelectProduct={handleSelectProduct}
      />
    </>
  );
};

export default BannerForm;
