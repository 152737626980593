import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import {
  A4_Size,
  downloadSizes,
  placeholderurldesktop,
  placeholderurllandscape,
  placeholderurlmobile,
  placeholderurlMobileNFT,
  placeholderurlpotrait,
  previewSizes,
  REMOVE_BG,
} from "../constant";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";
import Previewmodal from "./Previewmodal";
import ModelMobileNFT from "./ModelMobileNFT";
import ProductDetailMobile from "./ProductDetailMobile";
import LandscrapeModel from "./LandscrapeModel";
import QRCode from "react-qr-code";
import MessageSvg from "../SVG/MessageSvg";
import ASizeModal from "./ASizeModal";
import Starssvg from "../SVG/Starssvg";
import A4Modl from "./A4Modl";
import jsPDF from "jspdf";
import CleanBG from "../SVG/CleanBG";

const LivePreview = ({
  background,
  title,
  titleColor,
  titleFontSize,
  priceFontSize,
  footer,
  titleFontSizeMobile,
  nameFontSizeMobile,
  generatedQR,
  priceFontSizeMobile,
  regularPrice,
  showFooterInput,
  showTC,
  useUploadedBackground,
  nameFontSize,
  productDetails,
  titleFontFamily,
  customPrice,
  qrText,
  discription,
  nameColor,
  priceColor,
}) => {
  const [processedImage, setProcessedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imgRefs = useRef([]);

  useEffect(() => {
    const removeBackground = async () => {
      setLoading(true);
      try {
        const imageUrl =
          productDetails[0]?.imageurl instanceof Blob
            ? URL.createObjectURL(productDetails[0]?.imageurl)
            : productDetails[0]?.imageurl;

        const response = await axios.get(REMOVE_BG, {
          params: { url: imageUrl },
          responseType: "blob",
        });
        const url = URL.createObjectURL(response.data);
        setProcessedImage(url);
      } catch (error) {
        toast.error("Image Not Found");
      } finally {
        setLoading(false);
      }
    };

    if (productDetails && productDetails[0]?.imageurl) {
      removeBackground();
    }
  }, [productDetails]);

  const downloadAllBannersAsPng = async () => {
    const imgElements = imgRefs.current;

    for (let i = 0; i < imgElements.length; i++) {
      const img = imgElements[i];
      if (!img) continue;

      // Determine the resolution based on the banner type
      const bannerType = img.getAttribute("data-banner-type");
      const resolution = downloadSizes[bannerType];

      // Temporarily set the size of the element to match the download size
      const originalStyle = {
        width: img.style.width,
        height: img.style.height,
      };
      img.style.width = resolution.width;
      img.style.height = resolution.height;

      // Create a canvas with the correct dimensions
      const canvas = await html2canvas(img, {
        useCORS: true,
        allowTaint: true,
        scale: 1,
        backgroundColor: "#ffffff", // White background
      });

      // Reset the size of the element to its original dimensions
      img.style.width = originalStyle.width;
      img.style.height = originalStyle.height;

      // Save the canvas as a PNG file
      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `Downloaded-banner-${i + 1}.png`);
        }
      }, "image/png");
    }
  };

  const [currentBannerType, setCurrentBannerType] = useState("");

  const handleBannerClick = (bannerType) => {
    setCurrentBannerType(bannerType);
    setShowModal(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const downloadBannersAsPDF = async () => {
    const doc = new jsPDF("portrait", "pt", [793.7, 1122.52]); // Use A4 size in points

    // Capture the first banner (A4_Size)
    const a4SizeBanner = imgRefs.current[5];
    const a4Canvas = await html2canvas(a4SizeBanner);
    const a4ImgData = a4Canvas.toDataURL("image/png");
    doc.addImage(a4ImgData, "PNG", 0, 0, 793.7, 1122.52); // Fill the entire page

    // Add a page for the second banner
    doc.addPage();

    // Capture the second banner (A4Size)
    const a4SizeBanner2 = imgRefs.current[6];
    const a4Canvas2 = await html2canvas(a4SizeBanner2);
    const a4ImgData2 = a4Canvas2.toDataURL("image/png");
    doc.addImage(a4ImgData2, "PNG", 0, 0, 793.7, 1122.52); // Fill the entire page

    // Save the PDF
    doc.save("banners.pdf");
  };

  return (
    <>
      <div className="scrollable-form-container-live">
        <h3 className="banner-header">- Greenline Mobile</h3>
        <div
          className="banner-preview greenline-mobile"
          style={{ position: "relative", ...previewSizes.Greenline_Mobile }}
          ref={(el) => (imgRefs.current[0] = el)}
          data-banner-type="Greenline_Mobile"
          onClick={() => handleBannerClick("Greenline_Mobile")}
        >
          <img
            src={
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurlmobile
            }
            alt="Banner Background"
            className="live-bnr"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />

          <div className="banner-content">
            {title && (
              <div
                className="live-title-mobile"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}
            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div className="products-container-mobile">
                {productDetails?.map((product, index) => (
                  <div key={index} className="product-details-mobile">
                    {processedImage && (
                      <img
                        src={processedImage}
                        alt={product.name}
                        className="product-image"
                      />
                    )}
                    {product.name && (
                      <div
                        style={{
                          fontSize: nameFontSize,
                          color: nameColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        {product.name}
                      </div>
                    )}
                    {(customPrice || product.price) && (
                      <div
                        style={{
                          fontSize: priceFontSize,
                          color: priceColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        ${customPrice || product.price}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {(footer || showFooterInput) && (
              <div
                className="footer-style"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}
            {showTC && (
              <div
                className="T-and-C"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                T & C
              </div>
            )}
          </div>
        </div>

        <h3 className="banner-header">- Greenline Desktop</h3>
        <div
          className="banner-preview greenline-desktop"
          style={{ position: "relative", ...previewSizes.Greenline_Desktop }}
          ref={(el) => (imgRefs.current[1] = el)}
          data-banner-type="Greenline_Desktop"
          onClick={() => handleBannerClick("Greenline_Desktop")}
        >
          <img
            src={
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurldesktop
            }
            alt="Banner Background"
            className="live-bnr"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />

          {title && (
            <div
              className="live-title"
              style={{
                fontSize: titleFontSize,
                color: titleColor,
                fontFamily: titleFontFamily,
              }}
            >
              {title}
            </div>
          )}

          {loading ? (
            <div className="loading-styling">
              <ClipLoader
                color={"white"}
                loading={loading}
                alignContent="center"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div className="live-title">
              {productDetails?.map((product, index) => (
                <div key={index} className="Desktop-imgcont">
                  {processedImage && (
                    <img
                      src={processedImage}
                      alt={product.name}
                      className="Desktop-transparent-image"
                    />
                  )}
                  {product.name && (
                    <div
                      className="Desktop-nameTag"
                      style={{
                        fontSize: nameFontSize,
                        color: nameColor,
                        fontFamily: titleFontFamily,
                      }}
                    >
                      {product.name}
                    </div>
                  )}
                  {(customPrice || product.price) && (
                    <div
                      className="Desktop-priceTag"
                      style={{
                        fontSize: priceFontSize,
                        color: priceColor,
                        fontFamily: titleFontFamily,
                      }}
                    >
                      ${customPrice || product.price}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {(footer || showFooterInput) && (
            <div
              className="footer-style"
              style={{
                color: titleColor,
                fontFamily: titleFontFamily,
              }}
            >
              {footer}
            </div>
          )}
          {showTC && (
            <div
              className="T-and-C"
              style={{
                color: titleColor,
                fontFamily: titleFontFamily,
              }}
            >
              T & C
            </div>
          )}
        </div>

        <h3 className="banner-header">- Menu Board TV Landscape</h3>
        <div
          className="banner-preview greenline-mobile"
          style={{
            position: "relative",
            ...previewSizes.Menu_Board_TV_Landscape,
          }}
          ref={(el) => (imgRefs.current[2] = el)}
          data-banner-type="Menu_Board_TV_Landscape"
          onClick={() => handleBannerClick("Menu_Board_TV_Landscape")}
        >
          <img
            src={
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurllandscape
            }
            alt="Banner Background"
            className="live-bnr"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />

          <div className="banner-content-landscape">
            {title && (
              <div
                className="live-title-landscape"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div>
                {productDetails?.map((product, index) => (
                  <div key={index} className="Landscape-productContainer">
                    <div className="Landscape-imgPriceContainer">
                      {processedImage && (
                        <img
                          src={processedImage}
                          alt={product.name}
                          className="Landscape-transparent-image"
                        />
                      )}

                      {(customPrice || product.price) && (
                        <div
                          className="Landscape-priceTag"
                          style={{
                            fontSize: priceFontSize,
                            color: priceColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          ${customPrice || product.price}
                        </div>
                      )}
                    </div>

                    {product.name && (
                      <div
                        className="Landscape-nameTag"
                        style={{
                          fontSize: nameFontSize,
                          color: nameColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        {product.name}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-landscape-live"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}

            {showTC && (
              <div
                className="T-and-C-landscape-live"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                T & C
              </div>
            )}
          </div>
        </div>

        <h3 className="banner-header">- Portrait size</h3>
        <div
          className="banner-preview portrait"
          style={{ position: "relative", ...previewSizes.PORTRAIT_SIZE }}
          ref={(el) => (imgRefs.current[3] = el)}
          data-banner-type="PORTRAIT_SIZE" // Remove the leading space here
          onClick={() => handleBannerClick("PORTRAIT_SIZE")} // Make sure to pass the correct banner type
        >
          <img
            src={
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurlpotrait
            }
            alt="Banner Background"
            className="live-bnr"
          />
          <div className="banner-content">
            {title && (
              <div
                className="live-title-portrait"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div className="products-container-mobile">
                {productDetails?.map((product, index) => (
                  <div key={index} className="product-details-mobile">
                    {processedImage && (
                      <img
                        src={processedImage}
                        alt={product.name}
                        className="Portrait-transparent-image"
                      />
                    )}
                    {product.name && (
                      <div
                        className="Portrait-nameTag"
                        style={{
                          fontSize: nameFontSize,
                          color: nameColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        {product.name}
                      </div>
                    )}
                    {(customPrice || product.price) && (
                      <div
                        className="Portrait-priceTag"
                        style={{
                          fontSize: priceFontSize,
                          color: priceColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        ${customPrice || product.price}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-Portrait"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}

            {showTC && (
              <div
                className="T-and-C-Portrait"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                T & C
              </div>
            )}
          </div>
        </div>

        {/* Mobile NFT Size */}
        <h3 className="banner-header">- Mobile NFT </h3>
        <div
          className="banner-preview portrait"
          style={{ position: "relative", ...previewSizes.Mobile_NFT }}
          ref={(el) => (imgRefs.current[4] = el)} // Ensure this is the correct index
          data-banner-type="Mobile_NFT"
          onClick={() => handleBannerClick("Mobile_NFT")}
        >
          <img
            src={
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : placeholderurlMobileNFT
            }
            alt="Banner Background"
            className="live-bnr-nft"
          />
          <div className="banner-content">
            {title && (
              <div
                className="live-title-Mobile-NFT"
                style={{
                  fontSize: titleFontSizeMobile,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div className="products-container-mobile">
                {productDetails?.map((product, index) => (
                  <ProductDetailMobile
                    key={index}
                    product={product}
                    processedImage={processedImage}
                    nameFontSize={nameFontSize}
                    priceFontSizeMobile={priceFontSizeMobile}
                    titleFontSizeMobile={titleFontSizeMobile}
                    nameFontSizeMobile={nameFontSizeMobile}
                    nameColor={nameColor}
                    titleFontFamily={titleFontFamily}
                    customPrice={customPrice}
                    priceFontSize={priceFontSize}
                    priceColor={priceColor}
                  />
                ))}
              </div>
            )}
            <div>
              {(footer || showFooterInput) && (
                <div
                  className="footer-style-NFT"
                  style={{
                    color: titleColor,
                    fontFamily: titleFontFamily,
                  }}
                >
                  {footer}
                </div>
              )}

              {showTC && (
                <div
                  className="T-and-C-NFT"
                  style={{
                    color: titleColor,
                    fontFamily: titleFontFamily,
                  }}
                >
                  T & C
                </div>
              )}
              {generatedQR && (
                <div className="QR-Generator">
                  <QRCode value={generatedQR} />
                </div>
              )}
            </div>
          </div>
        </div>

        <h3 className="banner-header">- A4 Size</h3>
        <div
          className="banner-preview greenline-mobile"
          style={{
            position: "relative",
            ...previewSizes.A4_Size,
          }}
          ref={(el) => (imgRefs.current[5] = el)}
          data-banner-type="A4_Size"
          onClick={() => handleBannerClick("A4_Size")}
        >
          <img
            src={
              useUploadedBackground && background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : CleanBG
            }
            alt="Banner Background"
            className="live-bnr"
            crossOrigin="anonymous"
          />

          <div className="banner-content-a4">
            {title && (
              <div
                className="live-title-a4"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <>
                <div className="a4-productContainer">
                  {productDetails?.map((product, index) => (
                    <div key={index}>
                      {product.name && (
                        <div
                          className="a4-nameTag"
                          style={{
                            fontSize: nameFontSize,
                            color: nameColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {product.name}
                        </div>
                      )}

                      {discription && (
                        <div
                          className="a4-description"
                          style={{
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {discription}
                        </div>
                      )}

                      {(customPrice || product.price) && (
                        <div
                          className="a4-priceTag"
                          style={{
                            fontFamily: titleFontFamily,
                          }}
                        >
                          <p>Regular Price</p>
                          <div class="price-wrapper">
                            <div class="price-slash"></div>
                            <div class="price">${regularPrice}</div>
                          </div>
                          <p>Only for Today</p>${customPrice || product.price}
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="msgSVG">
                    <MessageSvg />
                  </div>
                </div>

                {processedImage && (
                  <div>
                    <img
                      src={processedImage}
                      alt="Product"
                      className="a4-transparent-image"
                    />
                  </div>
                )}
              </>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-a4"
                style={{
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}
          </div>
        </div>

        <h3 className="banner-header">- PDF Screen</h3>
        <div
          className="banner-preview greenline-mobile"
          style={{
            position: "relative",
            ...previewSizes.A4Size,
          }}
          ref={(el) => (imgRefs.current[6] = el)}
          data-banner-type="A4Size"
          onClick={() => handleBannerClick("A4Size")}
        >
          <img
            src={
              useUploadedBackground && background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : CleanBG
            }
            alt="Banner Background"
            className="live-bnr"
            crossOrigin="anonymous"
          />

          <div className="banner-content-a44">
            {title && (
              <div
                className="live-title-a44"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling">
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <>
                <div className="a44-productContainer">
                  {productDetails?.map((product, index) => (
                    <div key={index}>
                      {product.name && (
                        <div
                          className="a44-nameTag"
                          style={{
                            fontSize: nameFontSize,
                            color: nameColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {product.name}
                        </div>
                      )}

                      {discription && (
                        <div
                          className="a44-description"
                          style={{
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {discription}
                        </div>
                      )}

                      {(customPrice || product.price) && (
                        <div
                          className="a44-priceTag"
                          style={{
                            fontFamily: titleFontFamily,
                          }}
                        >
                          <p>Regular Price</p>
                          <div class="price-wrapper">
                        <div class="price-slash"></div>
                        <div class="price">${regularPrice}</div>
                      </div>
                          <p>Only for Today</p>${customPrice || product.price}
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="msgSVG">
                    <Starssvg />
                  </div>
                </div>

                {processedImage && (
                  <div>
                    <img
                      src={processedImage}
                      alt="Product"
                      className="a44-transparent-image"
                    />
                  </div>
                )}
              </>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-a44"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>
      {currentBannerType === "Mobile_NFT" ? (
        <ModelMobileNFT
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          generatedQR={generatedQR}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          titleFontSizeMobile={titleFontSizeMobile}
          priceFontSizeMobile={priceFontSizeMobile}
          nameFontSizeMobile={nameFontSizeMobile}
          footer={footer}
          discription={discription}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      ) : currentBannerType === "Menu_Board_TV_Landscape" ? (
        <LandscrapeModel
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          footer={footer}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      ) : currentBannerType === "A4_Size" ? (
        <ASizeModal
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          footer={footer}
          regularPrice={regularPrice}
          discription={discription}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      ) : currentBannerType === "A4Size" ? (
        <A4Modl
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          footer={footer}
          regularPrice={regularPrice}
          discription={discription}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      ) : (
        <Previewmodal
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          footer={footer}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      )}
      <Button
        className="reg-btn mb-3 big block button-border"
        onClick={downloadBannersAsPDF}
      >
        Download Banners as PDF
      </Button>

      {background && (
        <Button
          className="reg-btn mb-3 big block button-border"
          onClick={downloadAllBannersAsPng}
        >
          Download Banners
        </Button>
      )}
    </>
  );
};

export default LivePreview;
